<script lang="ts">
  import { writable, type Writable } from 'svelte/store';
  import { Task, WasabeeMarker, WasabeeOp } from '../../model';

  import GroupTree from './Group/Tree.svelte';
  import { Button } from '@sveltestrap/sveltestrap';
  export let opStore: Writable<WasabeeOp>;
  $: operation = $opStore;

  const nodes = writable<{
    [id: string]: {
      id: string;
      task?: Task;
      text: string;
      items?: { id: string }[];
    };
  }>({});

  $: {
    const ns: {
      [id: string]: {
        id: string;
        task?: Task;
        text: string;
        items?: { id: string }[];
      };
    } = {};
    const root = {
      id: 'root',
      text: 'op',
      items: [] as { id: string }[],
    };
    for (const m of operation.markers) {
      if (m.isPhaseEnd()) continue;
      ns[m.ID] = {
        id: m.ID,
        task: m,
        text: m.ID,
      };
      root.items.push({ id: m.ID });
    }
    for (const l of operation.links) {
      ns[l.ID] = {
        id: l.ID,
        text: l.ID,
        task: l,
      };
      root.items.push({ id: l.ID });
    }
    ns[root.id] = root;

    for (const m of operation.markers) {
      if (m.isPhaseEnd()) {
        const pairId = m.getPairedMarkerID()!;
        root.items = root.items.filter(
          (v) => !m.dependsOn.includes(v.id) || v.id === pairId,
        );
        ns[pairId].items = m.dependsOn
          .filter((v) => v !== pairId)
          .map((v) => ({
            id: v,
          }));
      }
    }
    console.log(ns);
    $nodes = ns;
  }

  function addGroup() {
    const somePortal = operation.opportals[0];
    operation.markers.unshift(...WasabeeMarker.createPhasePair(somePortal.id));
    operation.store();
    $opStore = operation;
  }
</script>

<div class="card mb-2">
  <div class="card-header" id="opName">
    {operation.name}
    <Button on:click={addGroup}>Add group</Button>
  </div>
</div>

<div class="row">
  <div class="col">
    <GroupTree id="root" {nodes} {operation} />
  </div>
</div>
