<script lang="ts">
  import { dndzone } from 'svelte-dnd-action';
  import { flip } from 'svelte/animate';
  import type { Writable } from 'svelte/store';
  import type { WasabeeOp } from '../../../model';
  import { Task as WasabeeTask } from '../../../model';
  import Task from './Task.svelte';

  interface Item {
    id: string;
    task?: WasabeeTask;
    text: string;
    items?: { id: string }[];
  }

  export let operation: WasabeeOp;
  export let id: string;
  export let nodes: Writable<{
    [id: string]: Item;
  }>;
  $: node = $nodes[id];
  $: text = node && node.text;
  $: items = node && node.items;
  $: task = node && node.task;

  function handleDndConsider(e: CustomEvent<DndEvent>) {
    items = e.detail.items as Item['items'];
  }
  function handleDndFinalize(e: CustomEvent<DndEvent>) {
    $nodes[id].items = e.detail.items as Item['items'];
  }
</script>

{#if task instanceof WasabeeTask}
  <Task {task} {operation} />
{:else}
  <p>{text}</p>
{/if}

{#if items}
  <section
    use:dndzone={{ items, dropTargetStyle: {} }}
    on:consider={handleDndConsider}
    on:finalize={handleDndFinalize}
  >
    {#each items as item (item.id)}
      <div class="item" animate:flip>
        <svelte:self id={item.id} {nodes} {operation} />
      </div>
    {/each}
  </section>
{/if}

<style>
  section {
    width: auto;
    border: 0px solid black;
    padding: 0.2em 1em;
    /* this will allow the dragged element to scroll the list */
    overflow-y: auto;
    height: auto;
    min-height: 0.5em;
    background-color: rgba(100, 100, 100, 0.1);
  }
  div {
    width: 95%;
    padding: 0.2em 1em;
    border: 0px solid blue;
    margin: 0.15em 0;
  }
  .item {
    background-color: rgba(00, 100, 100, 0.1);
  }
</style>
