<script lang="ts">
  import type { Task, WasabeeOp } from '../../../model';
  import PortalLink from '../PortalLink.svelte';
  import { WasabeeLink, WasabeeMarker } from '../../../model';
  import { agentsStore } from '../../../stores';
  import Agent from '../../Agent.svelte';

  export let operation: WasabeeOp;
  export let task: Task;

  $: agent = task.assignedTo && $agentsStore[task.assignedTo];
</script>

<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      {#if task instanceof WasabeeMarker}
        <img alt={task.type + task.state} src={task.icon} />
        {#if task.isPhaseMarker()}
          Group {task.ID}
        {:else}
          <PortalLink {operation} portalId={task.portalId} />
        {/if}
      {:else if task instanceof WasabeeLink}
        <span>Link:</span>
        <PortalLink {operation} portalId={task.fromPortalId} />
        <span> to </span>
        <PortalLink {operation} portalId={task.toPortalId} />
      {/if}
    </h5>
    <h6 class="card-subtitle mb-2 text-muted">
      <span class="order"><span class="label">Order:</span> {task.order}</span>
      {#if agent}<span class="assign"
          ><span class="label">Agent:</span> <Agent {agent} /></span
        >{/if}
      <span class="state"><span class="label">State:</span> {task.state}</span>
      <span class="zone"
        ><span class="label">Zone:</span> {operation.zoneName(task.zone)}</span
      >
    </h6>
  </div>
</div>

<style>
  .card {
    margin-top: unset;
  }
  img {
    width: 1em;
  }
  .label {
    text-decoration: underline dotted;
    padding-right: 1em;
  }
  .assign > :global(div) {
    display: inline-block;
  }
</style>
